import { default as aboutY24BCZHcviMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as eventsKSHQnM00k5Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_931mwbUOpMQkMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleohsSD7ae2fMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsZW1faz6dkbMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93G62MrM4rfxMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewFXc0h3AQr7Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexzhvYD6cRjTMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93I0negUwLavMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexPhdaRRz929Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93J5EB4O189dMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexQR1hgeGJwSMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionslBL8glvVCnMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingss0MLhmTwHlMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsqaCUb8WyAbMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_937URAineaxDMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as indexPfriHmnAzCMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirmT7ogRFe2LkMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirmdyx5DZ9uSvMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendarpLm7MUhJ6KMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/calendar.vue?macro=true";
import { default as chat4LpfW8DwJyMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/chat.vue?macro=true";
import { default as aboutV6k41BXyotMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedsx9t33JJg2Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersv9mE7ZO2tZMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_93V2oiFeFaNsMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45editnPSFIZPpZlMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_93UfwcpodWNfMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as community7TOgN3p1dDMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93JmetzWie0vMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93GLf0DTzJpZMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardlcG2Q5zKhHMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/dashboard.vue?macro=true";
import { default as indexGQQPjDPsqvMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutnpgWEQXIjdMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45productyIdmngptp6Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventsPtbEyiNNcYMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93yNWvNhw05PMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexBoSuug0AJDMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_9342SZCzON9xMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_931F5TqmTKRtMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as indexESHCpNblRfMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_93FG2mM3RabKMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joinkzDmcqNV4WMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/join.vue?macro=true";
import { default as loginq1JYrT28JOMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/login.vue?macro=true";
import { default as logoutvXJ61SmGG0Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_93y9mZ9q20DPMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_93wgblDv8L1bMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_93XoCrZNPjveMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favoritesuPKH0Get4pMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studiosEbsK19q9NFMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optinxj1BzUzZPdMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_937Mh9x45AY9Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue?macro=true";
import { default as indexxKAXfIcbrzMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indexB3LnAgLxwWMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as aboutbNsfU1QTwJMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as events2JU5py3rNVMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93YgbgDgtQwCMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleEfPtz7jqXsMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45products1WACsH9lRUMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93ODRFsjl4xzMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as view8lqble8VGTMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexpkFNvQxWxHMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93F71p6FuNI1Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexFaG8DYd4VsMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93vtfomdgt7hMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexhz5clZMOZSMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsPYE07aiW4NMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsE8QLWJzr4pMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviews0YwzTylGzRMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93HT2uZTrAqVMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as indexFDD1nrfBXrMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexufqOR4CO5jMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchasesErS7ba2DU7Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionsCzPjGkbxavMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93WFNB4dahG6Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_93kaxpLLxp1fMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as reset4TxcIR8Fv8Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/reset.vue?macro=true";
import { default as shop1Am0tsOTs6Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/shop.vue?macro=true";
import { default as signupKycvWwcENcMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/signup.vue?macro=true";
import { default as livep3Ed5GJxO0Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tipsYvXiB3rmH4Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_933BvRj0aWoSMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compactjCnyTtEQcxMeta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as fullq7ksA8JOY6Meta } from "/tmp/build_a5c0fc1f/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_937URAineaxDMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_937URAineaxDMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_937URAineaxDMeta || {},
    alias: _91product_id_937URAineaxDMeta?.alias || [],
    redirect: _91product_id_937URAineaxDMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutY24BCZHcviMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: aboutY24BCZHcviMeta?.path ?? "about",
    meta: aboutY24BCZHcviMeta || {},
    alias: aboutY24BCZHcviMeta?.alias || [],
    redirect: aboutY24BCZHcviMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsKSHQnM00k5Meta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: eventsKSHQnM00k5Meta?.path ?? "events",
    meta: eventsKSHQnM00k5Meta || {},
    alias: eventsKSHQnM00k5Meta?.alias || [],
    redirect: eventsKSHQnM00k5Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_931mwbUOpMQkMeta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_931mwbUOpMQkMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_931mwbUOpMQkMeta || {},
    alias: _91media_id_931mwbUOpMQkMeta?.alias || [],
    redirect: _91media_id_931mwbUOpMQkMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleohsSD7ae2fMeta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45sampleohsSD7ae2fMeta?.path ?? "free-sample",
    meta: free_45sampleohsSD7ae2fMeta || {},
    alias: free_45sampleohsSD7ae2fMeta?.alias || [],
    redirect: free_45sampleohsSD7ae2fMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsZW1faz6dkbMeta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45productsZW1faz6dkbMeta?.path ?? "included-products",
    meta: included_45productsZW1faz6dkbMeta || {},
    alias: included_45productsZW1faz6dkbMeta?.alias || [],
    redirect: included_45productsZW1faz6dkbMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93G62MrM4rfxMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93G62MrM4rfxMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93G62MrM4rfxMeta || {},
    alias: _91media_id_93G62MrM4rfxMeta?.alias || [],
    redirect: _91media_id_93G62MrM4rfxMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewFXc0h3AQr7Meta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: viewFXc0h3AQr7Meta?.path ?? "lessons/:lesson_id()/view",
    meta: viewFXc0h3AQr7Meta || {},
    alias: viewFXc0h3AQr7Meta?.alias || [],
    redirect: viewFXc0h3AQr7Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexzhvYD6cRjTMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indexzhvYD6cRjTMeta?.path ?? "lessons",
    meta: indexzhvYD6cRjTMeta || {},
    alias: indexzhvYD6cRjTMeta?.alias || [],
    redirect: indexzhvYD6cRjTMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93I0negUwLavMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93I0negUwLavMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93I0negUwLavMeta || {},
    alias: _91media_id_93I0negUwLavMeta?.alias || [],
    redirect: _91media_id_93I0negUwLavMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexPhdaRRz929Meta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexPhdaRRz929Meta?.path ?? "playlists/:playlist_id()",
    meta: indexPhdaRRz929Meta || {},
    alias: indexPhdaRRz929Meta?.alias || [],
    redirect: indexPhdaRRz929Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93J5EB4O189dMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93J5EB4O189dMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93J5EB4O189dMeta || {},
    alias: _91media_id_93J5EB4O189dMeta?.alias || [],
    redirect: _91media_id_93J5EB4O189dMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexQR1hgeGJwSMeta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: indexQR1hgeGJwSMeta?.path ?? "playlists",
    meta: indexQR1hgeGJwSMeta || {},
    alias: indexQR1hgeGJwSMeta?.alias || [],
    redirect: indexQR1hgeGJwSMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionslBL8glvVCnMeta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessionslBL8glvVCnMeta?.path ?? "private-sessions",
    meta: private_45sessionslBL8glvVCnMeta || {},
    alias: private_45sessionslBL8glvVCnMeta?.alias || [],
    redirect: private_45sessionslBL8glvVCnMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingss0MLhmTwHlMeta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordingss0MLhmTwHlMeta?.path ?? "recordings",
    meta: recordingss0MLhmTwHlMeta || {},
    alias: recordingss0MLhmTwHlMeta?.alias || [],
    redirect: recordingss0MLhmTwHlMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsqaCUb8WyAbMeta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewsqaCUb8WyAbMeta?.path ?? "reviews",
    meta: reviewsqaCUb8WyAbMeta || {},
    alias: reviewsqaCUb8WyAbMeta?.alias || [],
    redirect: reviewsqaCUb8WyAbMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexPfriHmnAzCMeta?.name ?? "buy-product-product_id",
    path: indexPfriHmnAzCMeta?.path ?? "/buy/product/:product_id()",
    meta: indexPfriHmnAzCMeta || {},
    alias: indexPfriHmnAzCMeta?.alias || [],
    redirect: indexPfriHmnAzCMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmT7ogRFe2LkMeta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirmT7ogRFe2LkMeta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirmT7ogRFe2LkMeta || {},
    alias: confirmT7ogRFe2LkMeta?.alias || [],
    redirect: confirmT7ogRFe2LkMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmdyx5DZ9uSvMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirmdyx5DZ9uSvMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirmdyx5DZ9uSvMeta || {},
    alias: confirmdyx5DZ9uSvMeta?.alias || [],
    redirect: confirmdyx5DZ9uSvMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendarpLm7MUhJ6KMeta?.name ?? "calendar",
    path: calendarpLm7MUhJ6KMeta?.path ?? "/calendar",
    meta: calendarpLm7MUhJ6KMeta || {},
    alias: calendarpLm7MUhJ6KMeta?.alias || [],
    redirect: calendarpLm7MUhJ6KMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chat4LpfW8DwJyMeta?.name ?? "chat",
    path: chat4LpfW8DwJyMeta?.path ?? "/chat",
    meta: chat4LpfW8DwJyMeta || {},
    alias: chat4LpfW8DwJyMeta?.alias || [],
    redirect: chat4LpfW8DwJyMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_93V2oiFeFaNsMeta?.name ?? "community-groups-group_id",
    path: _91group_id_93V2oiFeFaNsMeta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_93V2oiFeFaNsMeta || {},
    alias: _91group_id_93V2oiFeFaNsMeta?.alias || [],
    redirect: _91group_id_93V2oiFeFaNsMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutV6k41BXyotMeta?.name ?? "community-groups-group_id-about",
    path: aboutV6k41BXyotMeta?.path ?? "about",
    meta: aboutV6k41BXyotMeta || {},
    alias: aboutV6k41BXyotMeta?.alias || [],
    redirect: aboutV6k41BXyotMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedsx9t33JJg2Meta?.name ?? "community-groups-group_id-feed",
    path: feedsx9t33JJg2Meta?.path ?? "feed",
    meta: feedsx9t33JJg2Meta || {},
    alias: feedsx9t33JJg2Meta?.alias || [],
    redirect: feedsx9t33JJg2Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersv9mE7ZO2tZMeta?.name ?? "community-groups-group_id-members",
    path: membersv9mE7ZO2tZMeta?.path ?? "members",
    meta: membersv9mE7ZO2tZMeta || {},
    alias: membersv9mE7ZO2tZMeta?.alias || [],
    redirect: membersv9mE7ZO2tZMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45editnPSFIZPpZlMeta?.name ?? "community-profile-edit",
    path: community_45profile_45editnPSFIZPpZlMeta?.path ?? "/community-profile-edit",
    meta: community_45profile_45editnPSFIZPpZlMeta || {},
    alias: community_45profile_45editnPSFIZPpZlMeta?.alias || [],
    redirect: community_45profile_45editnPSFIZPpZlMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UfwcpodWNfMeta?.name ?? "community-profile-id",
    path: _91id_93UfwcpodWNfMeta?.path ?? "/community-profile/:id()",
    meta: _91id_93UfwcpodWNfMeta || {},
    alias: _91id_93UfwcpodWNfMeta?.alias || [],
    redirect: _91id_93UfwcpodWNfMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: community7TOgN3p1dDMeta?.name ?? "community",
    path: community7TOgN3p1dDMeta?.path ?? "/community",
    meta: community7TOgN3p1dDMeta || {},
    alias: community7TOgN3p1dDMeta?.alias || [],
    redirect: community7TOgN3p1dDMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93JmetzWie0vMeta?.name ?? "complete-registration-uuid",
    path: _91uuid_93JmetzWie0vMeta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93JmetzWie0vMeta || {},
    alias: _91uuid_93JmetzWie0vMeta?.alias || [],
    redirect: _91uuid_93JmetzWie0vMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93GLf0DTzJpZMeta?.name ?? "contracts-unique_id",
    path: _91unique_id_93GLf0DTzJpZMeta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93GLf0DTzJpZMeta || {},
    alias: _91unique_id_93GLf0DTzJpZMeta?.alias || [],
    redirect: _91unique_id_93GLf0DTzJpZMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardlcG2Q5zKhHMeta?.name ?? "dashboard",
    path: dashboardlcG2Q5zKhHMeta?.path ?? "/dashboard",
    meta: dashboardlcG2Q5zKhHMeta || {},
    alias: dashboardlcG2Q5zKhHMeta?.alias || [],
    redirect: dashboardlcG2Q5zKhHMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexGQQPjDPsqvMeta?.name ?? "event-details-event_id",
    path: indexGQQPjDPsqvMeta?.path ?? "/event/details/:event_id()",
    meta: indexGQQPjDPsqvMeta || {},
    alias: indexGQQPjDPsqvMeta?.alias || [],
    redirect: indexGQQPjDPsqvMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutnpgWEQXIjdMeta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutnpgWEQXIjdMeta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutnpgWEQXIjdMeta || {},
    alias: checkoutnpgWEQXIjdMeta?.alias || [],
    redirect: checkoutnpgWEQXIjdMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45productyIdmngptp6Meta?.name ?? "event-details-event_id-select-product",
    path: select_45productyIdmngptp6Meta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45productyIdmngptp6Meta || {},
    alias: select_45productyIdmngptp6Meta?.alias || [],
    redirect: select_45productyIdmngptp6Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventsPtbEyiNNcYMeta?.name ?? "events",
    path: eventsPtbEyiNNcYMeta?.path ?? "/events",
    meta: eventsPtbEyiNNcYMeta || {},
    alias: eventsPtbEyiNNcYMeta?.alias || [],
    redirect: eventsPtbEyiNNcYMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93yNWvNhw05PMeta?.name ?? "gifts-uuid",
    path: _91uuid_93yNWvNhw05PMeta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93yNWvNhw05PMeta || {},
    alias: _91uuid_93yNWvNhw05PMeta?.alias || [],
    redirect: _91uuid_93yNWvNhw05PMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexBoSuug0AJDMeta?.name ?? "gifts",
    path: indexBoSuug0AJDMeta?.path ?? "/gifts",
    meta: indexBoSuug0AJDMeta || {},
    alias: indexBoSuug0AJDMeta?.alias || [],
    redirect: indexBoSuug0AJDMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_9342SZCzON9xMeta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_9342SZCzON9xMeta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_9342SZCzON9xMeta || {},
    alias: _91invitation_id_9342SZCzON9xMeta?.alias || [],
    redirect: _91invitation_id_9342SZCzON9xMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_931F5TqmTKRtMeta?.name ?? "gifts-product-product_id",
    path: _91product_id_931F5TqmTKRtMeta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_931F5TqmTKRtMeta || {},
    alias: _91product_id_931F5TqmTKRtMeta?.alias || [],
    redirect: _91product_id_931F5TqmTKRtMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: indexESHCpNblRfMeta?.name ?? "index",
    path: indexESHCpNblRfMeta?.path ?? "/",
    meta: indexESHCpNblRfMeta || {},
    alias: indexESHCpNblRfMeta?.alias || [],
    redirect: indexESHCpNblRfMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_93FG2mM3RabKMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_93FG2mM3RabKMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_93FG2mM3RabKMeta || {},
    alias: _91invitation_unique_id_93FG2mM3RabKMeta?.alias || [],
    redirect: _91invitation_unique_id_93FG2mM3RabKMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joinkzDmcqNV4WMeta?.name ?? "join",
    path: joinkzDmcqNV4WMeta?.path ?? "/join",
    meta: joinkzDmcqNV4WMeta || {},
    alias: joinkzDmcqNV4WMeta?.alias || [],
    redirect: joinkzDmcqNV4WMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: loginq1JYrT28JOMeta?.name ?? "login",
    path: loginq1JYrT28JOMeta?.path ?? "/login",
    meta: loginq1JYrT28JOMeta || {},
    alias: loginq1JYrT28JOMeta?.alias || [],
    redirect: loginq1JYrT28JOMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logoutvXJ61SmGG0Meta?.name ?? "logout",
    path: logoutvXJ61SmGG0Meta?.path ?? "/logout",
    meta: logoutvXJ61SmGG0Meta || {},
    alias: logoutvXJ61SmGG0Meta?.alias || [],
    redirect: logoutvXJ61SmGG0Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_93y9mZ9q20DPMeta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_93y9mZ9q20DPMeta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_93y9mZ9q20DPMeta || {},
    alias: _91meeting_type_slug_93y9mZ9q20DPMeta?.alias || [],
    redirect: _91meeting_type_slug_93y9mZ9q20DPMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93wgblDv8L1bMeta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_93wgblDv8L1bMeta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_93wgblDv8L1bMeta || {},
    alias: _91meeting_unique_id_93wgblDv8L1bMeta?.alias || [],
    redirect: _91meeting_unique_id_93wgblDv8L1bMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93XoCrZNPjveMeta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_93XoCrZNPjveMeta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_93XoCrZNPjveMeta || {},
    alias: _91meeting_unique_id_93XoCrZNPjveMeta?.alias || [],
    redirect: _91meeting_unique_id_93XoCrZNPjveMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesuPKH0Get4pMeta?.name ?? "my-favorites",
    path: my_45favoritesuPKH0Get4pMeta?.path ?? "/my-favorites",
    meta: my_45favoritesuPKH0Get4pMeta || {},
    alias: my_45favoritesuPKH0Get4pMeta?.alias || [],
    redirect: my_45favoritesuPKH0Get4pMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studiosEbsK19q9NFMeta?.name ?? "my-studios",
    path: my_45studiosEbsK19q9NFMeta?.path ?? "/my-studios",
    meta: my_45studiosEbsK19q9NFMeta || {},
    alias: my_45studiosEbsK19q9NFMeta?.alias || [],
    redirect: my_45studiosEbsK19q9NFMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optinxj1BzUzZPdMeta?.name ?? "optin",
    path: optinxj1BzUzZPdMeta?.path ?? "/optin",
    meta: optinxj1BzUzZPdMeta || {},
    alias: optinxj1BzUzZPdMeta?.alias || [],
    redirect: optinxj1BzUzZPdMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_937Mh9x45AY9Meta?.name ?? "podcasts-podcast_id-episodes-episode_id",
    path: _91episode_id_937Mh9x45AY9Meta?.path ?? "/podcasts/:podcast_id()/episodes/:episode_id()",
    meta: _91episode_id_937Mh9x45AY9Meta || {},
    alias: _91episode_id_937Mh9x45AY9Meta?.alias || [],
    redirect: _91episode_id_937Mh9x45AY9Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: indexxKAXfIcbrzMeta?.name ?? "podcasts-podcast_id",
    path: indexxKAXfIcbrzMeta?.path ?? "/podcasts/:podcast_id()",
    meta: indexxKAXfIcbrzMeta || {},
    alias: indexxKAXfIcbrzMeta?.alias || [],
    redirect: indexxKAXfIcbrzMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexB3LnAgLxwWMeta?.name ?? "podcasts",
    path: indexB3LnAgLxwWMeta?.path ?? "/podcasts",
    meta: indexB3LnAgLxwWMeta || {},
    alias: indexB3LnAgLxwWMeta?.alias || [],
    redirect: indexB3LnAgLxwWMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93HT2uZTrAqVMeta?.name ?? "product-product_id",
    path: _91product_id_93HT2uZTrAqVMeta?.path ?? "/product/:product_id()",
    meta: _91product_id_93HT2uZTrAqVMeta || {},
    alias: _91product_id_93HT2uZTrAqVMeta?.alias || [],
    redirect: _91product_id_93HT2uZTrAqVMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutbNsfU1QTwJMeta?.name ?? "product-product_id-about",
    path: aboutbNsfU1QTwJMeta?.path ?? "about",
    meta: aboutbNsfU1QTwJMeta || {},
    alias: aboutbNsfU1QTwJMeta?.alias || [],
    redirect: aboutbNsfU1QTwJMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: events2JU5py3rNVMeta?.name ?? "product-product_id-events",
    path: events2JU5py3rNVMeta?.path ?? "events",
    meta: events2JU5py3rNVMeta || {},
    alias: events2JU5py3rNVMeta?.alias || [],
    redirect: events2JU5py3rNVMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93YgbgDgtQwCMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_93YgbgDgtQwCMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93YgbgDgtQwCMeta || {},
    alias: _91media_id_93YgbgDgtQwCMeta?.alias || [],
    redirect: _91media_id_93YgbgDgtQwCMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleEfPtz7jqXsMeta?.name ?? "product-product_id-free-sample",
    path: free_45sampleEfPtz7jqXsMeta?.path ?? "free-sample",
    meta: free_45sampleEfPtz7jqXsMeta || {},
    alias: free_45sampleEfPtz7jqXsMeta?.alias || [],
    redirect: free_45sampleEfPtz7jqXsMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45products1WACsH9lRUMeta?.name ?? "product-product_id-included-products",
    path: included_45products1WACsH9lRUMeta?.path ?? "included-products",
    meta: included_45products1WACsH9lRUMeta || {},
    alias: included_45products1WACsH9lRUMeta?.alias || [],
    redirect: included_45products1WACsH9lRUMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93ODRFsjl4xzMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93ODRFsjl4xzMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93ODRFsjl4xzMeta || {},
    alias: _91media_id_93ODRFsjl4xzMeta?.alias || [],
    redirect: _91media_id_93ODRFsjl4xzMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: view8lqble8VGTMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: view8lqble8VGTMeta?.path ?? "lessons/:lesson_id()/view",
    meta: view8lqble8VGTMeta || {},
    alias: view8lqble8VGTMeta?.alias || [],
    redirect: view8lqble8VGTMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexpkFNvQxWxHMeta?.name ?? "product-product_id-lessons",
    path: indexpkFNvQxWxHMeta?.path ?? "lessons",
    meta: indexpkFNvQxWxHMeta || {},
    alias: indexpkFNvQxWxHMeta?.alias || [],
    redirect: indexpkFNvQxWxHMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93F71p6FuNI1Meta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_93F71p6FuNI1Meta?.path ?? "media/:media_id()",
    meta: _91media_id_93F71p6FuNI1Meta || {},
    alias: _91media_id_93F71p6FuNI1Meta?.alias || [],
    redirect: _91media_id_93F71p6FuNI1Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexFaG8DYd4VsMeta?.name ?? "product-product_id-playlists-playlist_id",
    path: indexFaG8DYd4VsMeta?.path ?? "playlists/:playlist_id()",
    meta: indexFaG8DYd4VsMeta || {},
    alias: indexFaG8DYd4VsMeta?.alias || [],
    redirect: indexFaG8DYd4VsMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93vtfomdgt7hMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93vtfomdgt7hMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93vtfomdgt7hMeta || {},
    alias: _91media_id_93vtfomdgt7hMeta?.alias || [],
    redirect: _91media_id_93vtfomdgt7hMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexhz5clZMOZSMeta?.name ?? "product-product_id-playlists",
    path: indexhz5clZMOZSMeta?.path ?? "playlists",
    meta: indexhz5clZMOZSMeta || {},
    alias: indexhz5clZMOZSMeta?.alias || [],
    redirect: indexhz5clZMOZSMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsPYE07aiW4NMeta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionsPYE07aiW4NMeta?.path ?? "private-sessions",
    meta: private_45sessionsPYE07aiW4NMeta || {},
    alias: private_45sessionsPYE07aiW4NMeta?.alias || [],
    redirect: private_45sessionsPYE07aiW4NMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsE8QLWJzr4pMeta?.name ?? "product-product_id-recordings",
    path: recordingsE8QLWJzr4pMeta?.path ?? "recordings",
    meta: recordingsE8QLWJzr4pMeta || {},
    alias: recordingsE8QLWJzr4pMeta?.alias || [],
    redirect: recordingsE8QLWJzr4pMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviews0YwzTylGzRMeta?.name ?? "product-product_id-reviews",
    path: reviews0YwzTylGzRMeta?.path ?? "reviews",
    meta: reviews0YwzTylGzRMeta || {},
    alias: reviews0YwzTylGzRMeta?.alias || [],
    redirect: reviews0YwzTylGzRMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexFDD1nrfBXrMeta?.name ?? "product",
    path: indexFDD1nrfBXrMeta?.path ?? "/product",
    meta: indexFDD1nrfBXrMeta || {},
    alias: indexFDD1nrfBXrMeta?.alias || [],
    redirect: indexFDD1nrfBXrMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexufqOR4CO5jMeta?.name ?? "profile",
    path: indexufqOR4CO5jMeta?.path ?? "/profile",
    meta: indexufqOR4CO5jMeta || {},
    alias: indexufqOR4CO5jMeta?.alias || [],
    redirect: indexufqOR4CO5jMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesErS7ba2DU7Meta?.name ?? "profile-purchases",
    path: purchasesErS7ba2DU7Meta?.path ?? "/profile/purchases",
    meta: purchasesErS7ba2DU7Meta || {},
    alias: purchasesErS7ba2DU7Meta?.alias || [],
    redirect: purchasesErS7ba2DU7Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionsCzPjGkbxavMeta?.name ?? "profile-redemptions",
    path: redemptionsCzPjGkbxavMeta?.path ?? "/profile/redemptions",
    meta: redemptionsCzPjGkbxavMeta || {},
    alias: redemptionsCzPjGkbxavMeta?.alias || [],
    redirect: redemptionsCzPjGkbxavMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93WFNB4dahG6Meta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93WFNB4dahG6Meta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93WFNB4dahG6Meta || {},
    alias: _91quiz_id_93WFNB4dahG6Meta?.alias || [],
    redirect: _91quiz_id_93WFNB4dahG6Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93kaxpLLxp1fMeta?.name ?? "reset-password-uid-token",
    path: _91token_93kaxpLLxp1fMeta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_93kaxpLLxp1fMeta || {},
    alias: _91token_93kaxpLLxp1fMeta?.alias || [],
    redirect: _91token_93kaxpLLxp1fMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: reset4TxcIR8Fv8Meta?.name ?? "reset",
    path: reset4TxcIR8Fv8Meta?.path ?? "/reset",
    meta: reset4TxcIR8Fv8Meta || {},
    alias: reset4TxcIR8Fv8Meta?.alias || [],
    redirect: reset4TxcIR8Fv8Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shop1Am0tsOTs6Meta?.name ?? "shop",
    path: shop1Am0tsOTs6Meta?.path ?? "/shop",
    meta: shop1Am0tsOTs6Meta || {},
    alias: shop1Am0tsOTs6Meta?.alias || [],
    redirect: shop1Am0tsOTs6Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signupKycvWwcENcMeta?.name ?? "signup",
    path: signupKycvWwcENcMeta?.path ?? "/signup",
    meta: signupKycvWwcENcMeta || {},
    alias: signupKycvWwcENcMeta?.alias || [],
    redirect: signupKycvWwcENcMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: livep3Ed5GJxO0Meta?.name ?? "streaming-event_id-live",
    path: livep3Ed5GJxO0Meta?.path ?? "/streaming/:event_id()/live",
    meta: livep3Ed5GJxO0Meta || {},
    alias: livep3Ed5GJxO0Meta?.alias || [],
    redirect: livep3Ed5GJxO0Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tipsYvXiB3rmH4Meta?.name ?? "tips",
    path: tipsYvXiB3rmH4Meta?.path ?? "/tips",
    meta: tipsYvXiB3rmH4Meta || {},
    alias: tipsYvXiB3rmH4Meta?.alias || [],
    redirect: tipsYvXiB3rmH4Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_933BvRj0aWoSMeta?.name ?? "waiver-uuid",
    path: _91uuid_933BvRj0aWoSMeta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_933BvRj0aWoSMeta || {},
    alias: _91uuid_933BvRj0aWoSMeta?.alias || [],
    redirect: _91uuid_933BvRj0aWoSMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compactjCnyTtEQcxMeta?.name ?? "widgets-calendars-compact",
    path: compactjCnyTtEQcxMeta?.path ?? "/widgets/calendars/compact",
    meta: compactjCnyTtEQcxMeta || {},
    alias: compactjCnyTtEQcxMeta?.alias || [],
    redirect: compactjCnyTtEQcxMeta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: fullq7ksA8JOY6Meta?.name ?? "widgets-calendars-full",
    path: fullq7ksA8JOY6Meta?.path ?? "/widgets/calendars/full",
    meta: fullq7ksA8JOY6Meta || {},
    alias: fullq7ksA8JOY6Meta?.alias || [],
    redirect: fullq7ksA8JOY6Meta?.redirect,
    component: () => import("/tmp/build_a5c0fc1f/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]