import { createI18n } from 'vue-i18n'
import { getI18nConfig } from './../i18n.config.js'

export const i18n = createI18n(getI18nConfig());

export function updateTranslations(studioTranslations) {
  const translations = studioTranslations || [];
  for (const locale in translations) {
    if (translations.hasOwnProperty(locale)) {      
      i18n.global.mergeLocaleMessage(locale, translations[locale]);
    }
  }
}

export default i18n.global