<template>
  <div id="app" :style="cssVars">
    <link
      v-if="studio?.default_font"
      :href="`https://fonts.googleapis.com/css2?family=${studio.default_font}:wght@400;500;700&display=swap`"
      rel="stylesheet"
    />
    <Style>
      {{ styles }}
    </Style>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <ModalsContainer />
  </div>
</template>

<script lang="ts">
import { useGlobalsStore, useUserStore, useAffiliateStore, useWebsocketStore, useEventStore, useCommunityStore } from '@/stores';
import apiStudio from '@/api/studios';
import { updateTranslations, i18n } from '@/utils/localization';
import { mapState } from 'pinia'
import { hex2rgba } from '@/utils/color-converter';
import { ModalsContainer } from 'vue-final-modal';
import { getConfig } from '@/utils/configuration';
import { isStandalone } from '@/utils/detect-device';
import { injectScript, parseHeadContent } from '@/utils/inject-script';
import { setMixpanelUTMParameters } from '@/services/analytics';
import Cookies from 'js-cookie';
import { getGoogleAnalyticsScript, getUserbackScript, getRewardfulInitialScript } from '@/services/external-scripts';
import { metaTitles } from '@/utils/meta-utils';
import HMDialog from '@/components/common/HMDialog.vue';
import { initSocket } from '@/services/sockets';
import RefreshData from '@/mixins/refreshData';
import {
  subscribeStudioToCommunityNotifications,
  subscribeToCommunityNotifications,
  connectChatUser,
} from '@/services/stream';
import { setDeferedAddToHomescreen } from '@/utils/detect-device';
import { initCanvaButtonApi } from '@/services/canva';
import legacyVuexMigration from '@/utils/legacy-vuex-migration';

export default defineNuxtComponent({
  components: {
    ModalsContainer,
  },
  mixins: [RefreshData],
  async setup() {
    const { mergeLocaleMessage } = useI18n();
    const url = useRequestURL();
    const studioStore = useStudioStore();
    const userStore = useUserStore();
    const { $studioURL } = useNuxtApp();
    let studioTranslations;

    try {
      const [
        { data: translationsData },
        { data: studioData },
        { data: userInfoData }
      ] = await Promise.all([
        useAsyncData(
          `${$studioURL}_translations`,
          () => apiStudio.loadTranslations($studioURL)
        ),
        useAsyncData(
          `${$studioURL}_studio`,
          () => studioStore.getStudio($studioURL)
        ),
        useAsyncData(
          'user_info',
          () => userStore.loadUserInfo()
        )
      ]);

      try {
        const translations = translationsData.value;
        studioTranslations = translations.translations;
        if (studioTranslations) {
          for (const [key, value] of Object.entries(studioTranslations)) {
            mergeLocaleMessage(key, value);
          }
        }
      } catch (error) {
        console.error('Error loading translations', error);
      }


    } catch (error) {
      console.error('Error loading studio data', error);
      throw createError({
        statusCode: 404,
        statusMessage: "Problem loading studio",
      })
    }

    if(!$studioURL) {
      console.error('No studio URL provided');
      return {} 
    }

    useSeoMeta({
      ogTitle: studioStore.currentStudio?.studio_name,
      ogImage: studioStore.currentStudio?.cover_photo?.storage_url ?? studioStore.currentStudio?.cover_photo?.embedded_image_url,
      ogUrl: getCurrentUrl(url),
      twitterCard: 'summary_large_image',
    })

    const head = {
      title: studioStore.currentStudio?.studio_name,
      script: [
      ],
      link: [
        {
          rel: 'icon',
          href: studioStore.currentStudio?.favicon?.storage_url || `/hm-favicon.png`,
        },
      ],
    };

    if(studioStore.googleAnalyticsCode) {
      head.script.push({
        innerHTML: getGoogleAnalyticsScript(studioStore.googleAnalyticsCode, $studioURL),
      });
    }

    if (studioStore.rewardfulApiKey) {
      head.script.push({
        innerHTML: getRewardfulInitialScript(studioStore.rewardfulApiKey),
      });
    }
    if (studioStore.currentStudio?.code_embed_enabled && studioStore.currentStudio?.global_scripts) {
      try {
        const parsedScripts = parseHeadContent(studioStore.currentStudio.global_scripts)
        useHead(parsedScripts);
      } catch (error) {
        console.error('Error parsing global scripts', studioStore.currentStudio.global_scripts);
      }
    }

    if(studioStore.showUserbackInStudio) {
      head.script.push({
        innerHTML: getUserbackScript()
      });
    }
    useHead(head)

    return {
      studioTranslations,
      config: getConfig(url),
    };
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: state => state.currentStudio,
    }),
    ...mapState(useGlobalsStore, {
      isPWA: 'isPWA',
      showRefreshModal: 'showRefreshModal',
      backgroundImage: 'backgroundImage',
      affiliateCodeFromStore: 'affiliateCode',
    }),
    ...mapState(useUserStore, {
      teacher: state => state.teacher,
    }),
    backgroundImageURL() {
      return (
        this.backgroundImage?.storage_url ??
        this.backgroundImage?.embedded_image_url
      );
    },
    studioDefaultFont() {
      if (!this.studio?.default_font) return '';
      return this.studio?.default_font.replaceAll('+', ' ');
    },
    cssVars() {
      return {
        '--brand-color': this.studio?.primary_color,
        '--primary-color': this.studio?.primary_color,
        '--brand-font': this.studioDefaultFont,
        '--second-font': this.studioDefaultFont,
        '--brand-color-with-opacity-01': hex2rgba(
          this.studio?.primary_color,
          0.1
        ),
        '--brand-color-with-opacity-02': hex2rgba(
          this.studio?.primary_color,
          0.2
        ),
        '--brand-color-with-opacity-03': hex2rgba(
          this.studio?.primary_color,
          0.3
          ),
        '--brand-color-with-opacity-05': hex2rgba(
          this.studio?.primary_color,
          0.5
        ),
      };
    },
    styles() {
      return `:root {
        ${Object.entries(this.cssVars)
          .map(([key, value]) => `${key}: ${value};`)
          .join('\n')}
      }`;
    },
    showInactiveTeacherBanner() {
      return (
        !this.$route.meta?.isStudio && this.teacher && this.teacher.is_expired
      );
    },
    applicationName() {
      return this.config.applicationName;
    },
  },
  methods: {
    loadData() {
      useStudioStore().getStudio(this.$studioURL);
    },
    async checkAffiliateParameter() {
      if (!process.client) return;
      let affiliateCode = this.$route.query.ref;
      const storedAffiliateRef = Cookies.get('ref');

      if (affiliateCode && affiliateCode.startsWith('delete')) {
        Cookies.remove('ref');
        useGlobalsStore().setAffiliateCode(null);
        affiliateCode = null;
      } else {
        if (affiliateCode && !storedAffiliateRef) {
          const now = new Date();
          const exp = new Date(
            now.getFullYear(),
            now.getMonth() + 1,
            now.getDate()
          );
          Cookies.set('ref', affiliateCode, {
            expires: exp,
          });
          useGlobalsStore().setAffiliateCode(affiliateCode);
        } else if (storedAffiliateRef) {
          useGlobalsStore().setAffiliateCode(storedAffiliateRef);
        }
        if (this.affiliateCodeFromStore) {
          const affiliateDiscount = useAffiliateStore().trackAffiliateLinkClick({
            code: this.affiliateCodeFromStore,
            valid: !storedAffiliateRef || storedAffiliateRef === affiliateCode,
          });
          useAffiliateStore().setAffiliateCoupon(affiliateDiscount);
        }
      }
    },
    checkPWAParm() {
      if(!process.client) return;
      if (this.isPWA) return;
      const isPWA = this.$route.query.source === 'pwa';
      if (isPWA || isStandalone()) {
        useGlobalsStore().setIsPWA(true);
      }
    },
    checkPreviewTemplateParam() {
      if (this.$route.query.preview_template) {
        useGlobalsStore().setPreviewTemplate(this.$route.query.preview_template)
      }
    },
    setNotificationsToken(token) {
      // console.log('setNotificationsToken', token);
      const communityProfileId = useUserStore().studentCommunityProfileId;
      subscribeToCommunityNotifications(token, communityProfileId, data => {
        // console.log('Student community notification: ', data);
        useCommunityStore().processNotificationsCallback(data);
      });
      useCommunityStore().loadNotifications({
        markSeen: false,
      });
    },
    setStudioNotificationsToken(token) {
      const communityProfileId = useUserStore().studioCommunityProfileId;
      subscribeStudioToCommunityNotifications(token, communityProfileId, data => {
        // console.log('Studio community notification: ', data);
        useCommunityStore().processStudioNotificationsCallback(data);
      });
      useCommunityStore().loadStudioNotifications({
        markSeen: false,
      });
    },
    onEventStartingSoon(payload) {
      const studentId = useUserStore().studentId;
      if (payload.student_id === studentId) {
        // don't display multiple dialogs if dialog is still open
        if(!useStudioStore().eventStartingSoon) {
          useStudioStore().setEventStartingSoon(payload);
        }
      }
    },
    onEventUpdated(payload) {
      const currentEvent = useEventStore().event;
      if (payload.event_id === currentEvent?.id) {
        try {
          useEventStore().getStudioEventDetails({
            studioURL: currentEvent.shop_url,
            eventId: currentEvent.id,
          });
        } catch (error) {
          console.error('Error updating event', error);
          // ignore, it's most likely past event
        }
      }
    },
    async onUserChanged() {
      await useUserStore().reloadUserCommunityProfileAndNotifications();
      const communityProfile = useCommunityStore().studentCommunityProfileData;
      const user = useUserStore().user;

      if (communityProfile?.chat_token) {
        const id = user.community_profile_id;
        const name = user.community_last_name
          ? `${user.community_first_name} ${user.community_last_name}`
          : user.community_first_name;
        const image = user.community_profile_photo;
        connectChatUser(id, name, image, communityProfile.chat_token);
      }
    },
    onNewWebsocketEvent() {
      try {
        const event = useWebsocketStore().event;
        if(!event) return;
        let { event_type, payload } = event;
        event_type = event_type.toUpperCase();

        switch (event_type) {
          case 'EVENT.STARTING.SOON':
            this.onEventStartingSoon(payload);
            break;
          case 'EVENT.UPDATED':
            this.onEventUpdated(payload);
            break;
        }

      } catch (error) {
        console.error('Error processing websocket event', error);
      }      
    },
    recheckAccessToken() {
      const userStore = useUserStore();
      if (userStore.accessToken.value && userStore.role == 'anonymous') {
        userStore.setAccessToken(null);
      }
    },
  },
  watch: {
    '$route.query.source': {
      handler(_) {
        this.checkPWAParm();
      },
      immediate: true,
    },
    '$route.query.ref': {
      handler(_) {
        this.checkAffiliateParameter();
      },
      immediate: false,
    },
    '$route.query.preview_template': {
      handler(_) {
        this.checkPreviewTemplateParam();
      },
      immediate: true,
    },
    showRefreshModal: {
      handler(showRefreshModal) {
        if (showRefreshModal) {
          if (this.$route.meta.preventRefreshModal) return;

          const applicationName = this.applicationName;
          this.$modal.show(
            HMDialog,
            {
              title: this.$t('GENERAL.TITLE.NEW_RELEASE'),
              subtitle: this.$t('GENERAL.TEXT.PLEASE_REFRESH'),
              text: this.$t('GENERAL.TEXT.WE_HAVE_RELEASED_A_NEW_VERSION', {
                applicationName,
              }),
              confirmButton: this.$t('GENERAL.BUTTON.RELOAD'),
              discardButton: this.$t('GENERAL.BUTTON.CLOSE'),
              confirm: () => {
                window.location.reload();
              },
              discard: () => {
                useGlobalsStore().setShowRefreshModal(false);
                this.$attrs._close();
              },
            },
            {
              clickToClose: false,
              escToClose: false,
            }
          );
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    // this.recheckAccessToken();
    legacyVuexMigration();
    setMixpanelUTMParameters(this.$route.query);
    setTimeout(() => this.checkAffiliateParameter(), 1000);
    initSocket();
    updateTranslations(this.studioTranslations);
    this.onUserChanged();

    window.addEventListener('beforeinstallprompt', setDeferedAddToHomescreen);
    initCanvaButtonApi(process.env.VUE_APP_CANVA_BUTTON_API_KEY);

    watch(() => useUserStore().userId, () => this.onUserChanged());

    watch(() => useCommunityStore().notificationsToken, () => {
      this.setNotificationsToken(useCommunityStore().notificationsToken);
    });

    watch(() => useCommunityStore().studioNotificationsToken, () => {
      this.setStudioNotificationsToken(useCommunityStore().studioNotificationsToken);
    });

    watch(() => useWebsocketStore().event, () => {
      this.onNewWebsocketEvent();
    });
  },
});
</script>